/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $("#language-toggle").click(function () {
          $(".lang-item").slideToggle(300);
          $("ul#lang").toggleClass("active");
        });

        /*jQuery(window).on('load', function() {
          setTimeout(function(){
            jQuery('#preloader-wrapper').addClass('preloader-hide');
          }, 2000);
          setTimeout(function(){        
            jQuery('#preloader-wrapper').fadeOut("slow");
            jQuery('body').removeClass('overflow-hidden'); 
            jQuery('body').addClass('no-overflow'); 
          }, 0);
        });*/

        var wow = new WOW({
          boxClass: "wow", // animated element css class (default is wow)
          animateClass: "animated", // animation css class (default is animated)
          offset: 0, // distance to the element when triggering the animation (default is 0)
          mobile: false, // trigger animations on mobile devices (default is true)
          //live:         true,       // act on asynchronously loaded content (default is true)
          callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
            //console.log(box);
          },
          scrollContainer: null, // optional scroll container selector, otherwise use window,
          resetAnimation: true, // reset animation on end (default is true)
        });
        wow.init();

        /*$('#search-toggle').click(function(){
          $('body').addClass('active-search');
          $('#search-block').addClass('active');
          $('#search-block').removeClass('inactive');
        });*/

        $(document).on("click", "#search-close", function () {
          $("body").removeClass("active-search");
          $("#search-block").removeClass("active");
          $("#search-block").addClass("inactive");
        });

        // CONTACTS

        $("input[name='acceptance-marketing']").click(function () {
          if ($(this).is(":checked")) {
            $("#contact_submit").attr("disabled", false);
          } else {
            $("#contact_submit").attr("disabled", true);
          }
        });

        // END CONTACTS

        // ACCESSORIES
        if ($("#car-models").length > 0) {
          $(".child-variant").change(function () {
            var data_url = $(this).find(":selected").attr("data-url");
            $(".car-select").attr("href", data_url);
          });

          $(".child-variant").select2({
            minimumResultsForSearch: -1,
          });
        }
        // END ACCESSORIES

        $("#page_menu").select2({
          minimumResultsForSearch: -1,
        });

        $(".account_menu_mobile").select2({
          minimumResultsForSearch: -1,
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // MENU
        $(".lang-menu > li > a").click(function () {
          $(".lang-menu, .sub-menu").toggleClass("active");
        });

        /*$("body:not(.sub_menu)").click(function() {
          $(".sub-menu").removeClass("active");
        });*/
        // END MENU

        function textLength(value) {
          return value.length;
        }

        $("#search_vin").on("click", function (e) {
          $(".vin-results").addClass("hidden", function () {
            $(this).html("");
          });

          if ($("#vin").val()) {
            $("#vin").removeClass("error");
            var dig_4 = $("#vin").val().charAt(3);
            var dig_5 = $("#vin").val().charAt(4);
            var dig_10 = $("#vin").val().charAt(9);

            var data = {
              action: "get_model",
              post_type: "POST",
              dig_modelo: dig_4 + dig_5,
              dig_ano: dig_10,
            };

            $.post(ajaxurl, data, function (response) {
              var spl = response.split(";;");
              if (spl[0] == 1) location.href = spl[1];
              else
                $(".vin-results")
                  .html(spl[1])
                  .removeClass("hidden", function () {
                    $(this).fadeIn();
                  });
            });
          } else {
            $("#vin").addClass("error");
          }
        });

        // ao clicar em "Aceitar" coloca todos os tipos de cookies ativos
        $(".popup-container .action-button button").click(function () {
          var data = {
            'action': 'accept_cookies',
            'post_type': 'POST'
          };
      
          $.post(ajaxurl, data, function (response) {
            //$(".popup-container").addClass("disabled");
            location.reload();
          });
        });
      
        // ao clicar em "Guardar" altera a seleção do utilizador
        $(".page-template-template-cookies div.action-button button").click(function () {
          var gdpr_performance = $("#gdpr_performance").prop("checked");
          var gdpr_targeting = $("#gdpr_targeting").prop("checked");
      
          var data = {
            'action': 'save_cookies',
            'post_type': 'POST',
            performance: gdpr_performance,
            targeting: gdpr_targeting
          };
      
          $.post(ajaxurl, data, function (response) {
            location.reload();
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        // Instagram Swiper
        var instagram = new Swiper("#intagram-swiper.swiper-container", {
          slidesPerView: "4",
          spaceBetween: 16,
          pagination: {
            el: "#instagram-swipe.swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 1,
          },
          breakpoints: {
            1000: {
              slidesPerView: "2",
            },
          },
        });

        //Banner Swiper
        var titles = [];
        $("#banner-wrap #banner-slides .swiper-slide").each(function (i) {
          titles.push($(this).attr("data-title"));
        });

        var transitionTime = 5000;

        var countSwiper = new Swiper("#banner-wrap #count-inner", {
          noSwiping: true,
          touchRatio: 0,
          speed: 50,
          loop: true,
          effect: "none",
          coverflowEffect: {
            rotate: 0,
            slideShadows: false,
            depth: 0,
          },
          autoplay: "true",
          direction: "vertical",
          breakpoints: {
            2000: {
              noSwiping: true,
            },
            768: {
              noSwiping: true,
            },
          },
        });

        var bannerSwiper = new Swiper("#banner-wrap #banner-slides", {
          noSwiping: false,
          //autoplay: false,
          touchRatio: 0,
          speed: 800,
          loop: true,
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
          autoplay: {
            delay: transitionTime,
          },
          breakpoints: {
            768: {
              noSwiping: false,
              touchRatio: 1,
            },
          },
          controller: {
            control: [countSwiper],
          },
          pagination: {
            el: "#banner-wrap .swiper-pagination",
            type: "bullets",
            clickable: true,
          },
          navigation: {
            nextEl: "#banner-wrap  .arrow-next",
            prevEl: "#banner-wrap  .arrow-prev",
          },
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    acessorios: {
      init: function () {
        //Getting the Parameters from the URL
        function getParameterByName(name, url) {
          if (!url) {
            url = window.location.href;
          }
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
          if (!results) {
            return null;
          }
          if (!results[2]) {
            return "";
          }
          return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        //Checking if filters exist and applying their values
        function getCurrentParameters(key, value, extra, action) {
          if (action === undefined) action = "ajax";

          var newUrl;
          currentUrl = window.location.href;
          currentParams = getParameterByName(key);
          if (currentParams != null) {
            if (key === "orderby") {
              if (currentParams === value) {
                orderParam = getParameterByName("order");
                newUrl = currentUrl.replace(orderParam, extra);
              } else {
                orderParam = getParameterByName("order");
                var tempUrl = currentUrl.replace(currentParams, value);
                newUrl = tempUrl.replace(orderParam, extra);
              }
            } else {
              if (currentParams === value) {
                newUrl = currentUrl.replace(key + "=" + value, "");
              } else {
                newUrl = currentUrl.replace(currentParams, value);
              }
            }
          } else {
            if (currentUrl.indexOf("?") >= 0) {
              if (key === "orderby") {
                newUrl =
                  currentUrl + "&" + key + "=" + value + "&order=" + extra;
              } else {
                newUrl = currentUrl + "&" + key + "=" + value;
              }
            } else {
              if (key === "orderby") {
                newUrl =
                  currentUrl + "?" + key + "=" + value + "&order=" + extra;
              } else {
                newUrl = currentUrl + "?" + key + "=" + value;
              }
              //console.log(newUrl);
            }
            //console.log(newUrl);
          }

          //loadAccessories(newUrl, "create", action);
          filterClick(newUrl, "create", key, value);

          //window.location.href = newUrl;
        }

        /*function loadAccessories(url, flag, action) {
          if(flag === undefined) flag = "create";
          if(action === undefined) action = "ajax";

          if(action == "reload") {
            window.location.href = url;
          } else {
            var spl = url.split("?");
            var spl2 = spl[1].split("&");
            var loadmore_params = "";
            for(var i=0; i<spl2.length; i++) {
              var spl = spl2[i].split("=");

              if(spl[0] == "range") {
                $("#price_loadmore").attr("data-meta-value", spl[1]);
                loadmore_params += document.querySelector('#price_loadmore').dataset;
              } else if(spl[0] == "product_cat") {
                loadmore_params += document.querySelector('.sidebar-category-item input[type=checkbox][data-term="'+spl[1]+'"]').dataset;
              } else if(spl[0] == "gama") {
                loadmore_params += document.querySelector('.sidebar-gama-item input[type=checkbox][data-term="'+spl[1]+'"]').dataset;
              } else {
                loadmore_params += document.querySelector('#orderby_loadmore').dataset;
              }
            }

            console.log(loadmore_params);
          }
        }*/

        function filterClick(url, flag, key, value) {
          var transition = "fade";
          var speed = 250;
          var data;

          if (key == "range") {
            $("#price_loadmore").attr("data-meta-value", value);
            data = document.querySelector("#price_loadmore").dataset;
          } else if (key == "product_cat") {
            data = document.querySelector(
              '.sidebar-category-item input[type=checkbox][data-term="' +
                value +
                '"]'
            ).dataset;
          } else if (key == "gama") {
            data = document.querySelector(
              '.sidebar-gama-item input[type=checkbox][data-term="' +
                value +
                '"]'
            ).dataset;
          } else {
            data = document.querySelector("#orderby_loadmore").dataset;
          }

          // Call Ajax Load More `filter` method
          ajaxloadmore.filter(transition, speed, data);

          // atualiza o estado do histórico do browser e o url
          // se navegar com botões trás e frente do browser atualiza o estado que já tinha sido criado
          if (flag == "update") {
            window.history.replaceState(
              "Acessórios",
              "Acessórios - Honda Merch",
              url
            );
          } else {
            window.history.pushState(
              "Acessórios",
              "Acessórios - Honda Merch",
              url
            );
          }

          atualizaInputs(url); // atualiza inputs que vêm do url
        }

        window.almComplete = function (alm) {
          $(".total_accessories").html(alm.totalposts).fadeIn();

          var productimageSwiper = new Swiper(
            ".product-image.swiper-container",
            {
              noSwiping: true,
              slidesPerView: 1,
              loop: true,
              touchRatio: 0,
              on: {
                init: function () {
                  const numberOfSlides = this.slides.length - 2;
                  if (numberOfSlides == 1) {
                    $(this.params.el)
                      .find(".swiper-navigation, .swiper-pagination")
                      .css("display", "none");
                  }
                },
              },
              pagination: {
                el: "#product-image .swiper-pagination",
                type: "progressbar",
              },
              navigation: {
                nextEl: "#product-image .arrow-next",
                prevEl: "#product-image .arrow-prev",
              },
            }
          );
        };

        // callback que ocorre sempre que haja alteração do pedido loadmore
        window.almOnChange = function (alm) {
          var taxonomy = alm.content.dataset.taxonomy;
          var taxonomy_terms = alm.content.dataset.taxonomyTerms;
          var taxonomy_operator = alm.content.dataset.taxonomyOperator;
          var orderby = alm.content.dataset.orderby;
          var order = alm.content.dataset.order;
          var range = alm.content.dataset.metaValue;

          var spl_taxonomy = taxonomy.split(":");
          var spl_taxonomy_terms = taxonomy_terms.split(":");

          var operator = [];
          for (var i = 0; i < spl_taxonomy_terms.length; i++) {
            operator.push("IN");
          }
          taxonomy_operator = operator.join(":");

          // atualiza atributos loadmore das checkboxes categorias
          $(".sidebar-checkbox").each(function () {
            var cat_url = $(this).data("term");

            if (taxonomy.indexOf(":product_cat") !== -1) {
              var idx = spl_taxonomy.indexOf("product_cat");
              var termo = spl_taxonomy_terms[idx];
              var taxonomy_terms_temp = taxonomy_terms.replace(termo, cat_url);

              $(this).attr("data-taxonomy", taxonomy);
              $(this).attr("data-taxonomy-terms", taxonomy_terms_temp);
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            } else {
              $(this).attr("data-taxonomy", taxonomy + ":product_cat");
              $(this).attr(
                "data-taxonomy-terms",
                taxonomy_terms + ":" + cat_url
              );
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            }
          });

          // actualiza atributos loadmore do campo escondido orderby_loadmore
          if (orderby) {
            if (orderby == "meta_value_num") {
              $("#orderby_loadmore").attr("data-orderby", orderby);
              $("#orderby_loadmore").attr("data-meta-key", "_price");
            } else {
              $("#orderby_loadmore").attr("data-orderby", orderby);
              $("#orderby_loadmore").attr("data-meta-key", "");
            }
          }
          if (order) {
            $("#orderby_loadmore").attr("data-order", order);
          }

          // actualiza slider de preços
          //var cat = "acessorios";
          var cat = 131;
          var data = {
            action: "refresh_price_slider",
            post_type: "POST",
            cat: cat,
            url: window.location.href,
          };

          $.post(ajaxurl, data, function (response) {
            $(".price-input-wrap").html(response);

            var minRange;
            var maxRange;
            var rangeParam = getParameterByName("range");
            if (rangeParam != null) {
              var res = rangeParam.split(",");
              //console.log(res);
              minRange = Math.floor(res[0]);
              maxRange = Math.ceil(res[1]);
            } else {
              minRange = Math.floor($("#min-amount").val());
              maxRange = Math.ceil($("#max-amount").val());
            }

            //Function to Operate the Price Range Slider
            var minPrice = Math.floor($("#min-amount").val());
            var maxPrice = Math.ceil($("#max-amount").val());
            $("#slider-range").slider({
              range: true,
              min: minPrice,
              max: maxPrice,
              values: [minRange, maxRange],
              slide: function (event, ui) {
                for (var i = 0; i < ui.values.length; ++i) {
                  $("input.price-range-input[data-index=" + i + "]").val(
                    ui.values[i]
                  );
                  resetActive();
                }
              },
            });

            $("#min-amount").val(minRange);
            $("#max-amount").val(maxRange);
            $("#price_loadmore").attr(
              "data-meta-value",
              minRange + "," + maxRange
            );
          });
        };

        function atualizaInputs(url) {
          // categorias
          if (url.indexOf("product_cat") !== -1) {
            var cat_url = getParameterByName("product_cat", url);
            $(
              ".sidebar-category-item input[type='checkbox'][data-term!='" +
                cat_url +
                "']"
            ).each(function () {
              $(this).prop("checked", false);
            });
          }

          // slider
          var minRange = Math.floor($("#min-amount-bd").val());
          var maxRange = Math.ceil($("#max-amount-bd").val());
          var minStart = minRange;
          var maxStart = maxRange;

          if (url.indexOf("range") !== -1) {
            var range = getParameterByName("range", url).split(",");
            minRange = Math.floor(range[0]);
            maxRange = Math.ceil(range[1]);

            minStart = Math.floor($("#min-amount-bd").val());
            maxStart = Math.ceil($("#max-amount-bd").val());
          }

          $("#slider-range").slider({
            range: true,
            min: minStart,
            max: maxStart,
            values: [minRange, maxRange],
            slide: function (event, ui) {
              for (var i = 0; i < ui.values.length; ++i) {
                $("input.price-range-input[data-index=" + i + "]").val(
                  ui.values[i]
                );
                resetActive();
              }
            },
          });

          $("#min-amount").val(minRange);
          $("#max-amount").val(maxRange);
          // fim slider

          // order by
          if (url.indexOf("orderby") !== -1) {
            var orderby = getParameterByName("orderby", url);
            var order = getParameterByName("order", url);

            $("#product-sort")
              .find("li")
              .each(function () {
                $(this).removeClass("active");
              });
            $("a[data-sort='" + orderby + "'][data-order='" + order + "']")
              .parent()
              .addClass("active");
          } else {
            $("#product-sort")
              .find("li")
              .each(function () {
                $(this).removeClass("active");
              });
          }
          // end order by
        }

        // quando se navega no histórico do browser (botão frente e atrás) atualiza o estado criado antes para não criar
        // sempre o mesmo histórico no browser, senão navegamos sempre na mesma página
        window.onpopstate = function (event) {
          //console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
          //loadAccessories(document.location.href, "update");
          location.reload();
        };

        var activityTimeout;

        // No activity do something.
        function inActive() {
          var priceRangeLow = $("#min-amount").val();
          var priceRangeHigh = $("#max-amount").val();
          getCurrentParameters(
            "range",
            priceRangeLow + "," + priceRangeHigh,
            ""
          );
        }

        function resetActive() {
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(inActive, 2000);
        }

        // JavaScript to be fired on the about us page
        var minRange;
        var maxRange;
        var rangeParam = getParameterByName("range");
        if (rangeParam != null) {
          var res = rangeParam.split(",");
          //console.log(res);
          minRange = Math.floor(res[0]);
          maxRange = Math.ceil(res[1]);
        } else {
          minRange = Math.floor($("#min-amount").val());
          maxRange = Math.ceil($("#max-amount").val());
        }
        //Function to Operate the Price Range Slider
        var minPrice = Math.floor($("#min-amount").val());
        var maxPrice = Math.ceil($("#max-amount").val());
        $("#slider-range").slider({
          range: true,
          min: minPrice,
          max: maxPrice,
          values: [minRange, maxRange],
          slide: function (event, ui) {
            for (var i = 0; i < ui.values.length; ++i) {
              $("input.price-range-input[data-index=" + i + "]").val(
                ui.values[i]
              );
              resetActive();
            }
          },
        });

        $("#min-amount").val(minRange);
        $("#max-amount").val(maxRange);
        $("#price_loadmore").attr("data-meta-value", minRange + "," + maxRange);

        $("input.price-range-input").change(function () {
          var $this = $(this);
          $("#slider-range").slider("values", $this.data("index"), $this.val());
          resetActive();
        });

        $("a.sort-item-btn").click(function () {
          $("a.sort-item-btn").removeClass("active");
          $(this).addClass("active");
          var btnKey = $(this).attr("data-sort");
          var btnValue = $(this).attr("data-order");
          var btnExtra = "sorting";
          if (btnKey == "_price") {
            $("#orderby_loadmore").attr("data-orderby", "meta_value_num");
            $("#orderby_loadmore").attr("data-meta-key", btnKey);
          } else {
            $("#orderby_loadmore").attr("data-orderby", btnKey);
            $("#orderby_loadmore").attr("data-meta-key", "");
          }
          $("#orderby_loadmore").attr("data-order", btnValue);
          $("#sort-toggle").click();
          getCurrentParameters("orderby", btnKey, btnValue);
        });

        $("a.sidebar-model").click(function () {
          var btnValue;
          if ($(this).attr("id") !== "model-all") {
            $("a.sidebar-model").removeClass("active");
            $(this).addClass("active");
            btnValue = $(this).attr("data-model");
            document.location = "acessorios/?car_model=" + btnValue;
            //getCurrentParameters('car_model', btnValue, '', 'reload');
          } else {
            if (!$(this).hasClass("active")) {
              $("a.sidebar-model").removeClass("active");
              $(this).addClass("active");
              btnValue = $(this).attr("data-model");
              document.location = "acessorios/?car_model=" + btnValue;
              //getCurrentParameters('car_model', btnValue, '', 'reload');
            }
          }
        });

        $(".sidebar-checkbox").change(function () {
          var cat_url = $(this).data("term");
          if ($(this).prop("checked")) {
            getCurrentParameters("product_cat", cat_url, "");
          } else {
            var url = document.location.href;
            url = url
              .replace("?product_cat=" + cat_url, "")
              .replace("&product_cat=" + cat_url, "");
            if (url.indexOf("?") === -1) url = url.replace("&", "?");
            window.history.pushState(
              "Acessórios",
              "Acessórios - Honda Merch",
              url
            );
            window.location.href = url;
          }
        });

        function findActiveFilters() {
          var orderbyParam = getParameterByName("orderby");
          var orderedParam = getParameterByName("order");
          var modelParam = getParameterByName("car_model");
          var catParam = getParameterByName("product_cat");
          //var rangeParam = getParameterByName('range');
          if (orderbyParam != null) {
            var activeSort = $(
              'a[data-sort="' +
                orderbyParam +
                '"][data-order="' +
                orderedParam +
                '"]'
            );
            activeSort.addClass("active");
            $("#current-sort").html(activeSort.html());
          } //
          if (modelParam != null) {
            $("#model-all").removeClass("active");
            $("a#model-" + modelParam).addClass("active");
            $("a#model-" + modelParam)
              .parent()
              .parent()
              .addClass("active")
              .prev()
              .addClass("open");
          }
          if (catParam != null) {
            $("#category-" + catParam).prop("checked", true);
          }
        }
        findActiveFilters();
        $("#model-all.active").click(function () {
          return false;
        });

        $("a.toggle-accordion").click(function () {
          /*$(".toggle-accordion").not($(this)).removeClass("open");
          $(".sidebar-model-child").not($(this).next()).slideUp("fast");*/

          $(this).toggleClass("open");
          $(this).next().slideToggle("fast");
        });

        $("#sort-toggle").click(function () {
          $(this).toggleClass("open");
          $("#product-sort").toggleClass("active");
        });
      },
    },
    merchandising: {
      init: function () {
        //Getting the Parameters from the URL
        function getParameterByName(name, url) {
          if (!url) {
            url = window.location.href;
          }
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
          if (!results) {
            return null;
          }
          if (!results[2]) {
            return "";
          }
          return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        //Checking if filters exist and applying their values
        function getCurrentParameters(key, value, extra, action) {
          if (action === undefined) action = "ajax";

          var newUrl;
          currentUrl = window.location.href;
          currentParams = getParameterByName(key);
          if (currentParams != null) {
            if (key === "orderby") {
              if (currentParams === value) {
                orderParam = getParameterByName("order");
                newUrl = currentUrl.replace(orderParam, extra);
              } else {
                orderParam = getParameterByName("order");
                var tempUrl = currentUrl.replace(currentParams, value);
                newUrl = tempUrl.replace(orderParam, extra);
              }
            } else {
              if (currentParams === value) {
                newUrl = currentUrl.replace(key + "=" + value, "");
              } else {
                newUrl = currentUrl.replace(currentParams, value);
              }
            }
          } else {
            if (currentUrl.indexOf("?") >= 0) {
              if (key === "orderby") {
                newUrl =
                  currentUrl + "&" + key + "=" + value + "&order=" + extra;
              } else {
                newUrl = currentUrl + "&" + key + "=" + value;
              }
            } else {
              if (key === "orderby") {
                newUrl =
                  currentUrl + "?" + key + "=" + value + "&order=" + extra;
              } else {
                newUrl = currentUrl + "?" + key + "=" + value;
              }
              //console.log(newUrl);
            }
            //console.log(newUrl);
          }

          //loadAccessories(newUrl, "create", action);
          filterClick(newUrl, "create", key, value);

          //window.location.href = newUrl;
        }

        /*function loadMerchandising(url, flag, action) {
          if(flag === undefined) flag = "create";
          if(action === undefined) action = "ajax";

          if(action == "reload") {
            window.location.href = url;
          } else {
            var spl = url.split("?");
            var spl2 = spl[1].split("&");
            var loadmore_params = "";
            for(var i=0; i<spl2.length; i++) {
              var spl = spl2[i].split("=");

              if(spl[0] == "range") {
                $("#price_loadmore").attr("data-meta-value", spl[1]);
                loadmore_params += document.querySelector('#price_loadmore').dataset;
              } else if(spl[0] == "product_cat") {
                loadmore_params += document.querySelector('.sidebar-category-item input[type=checkbox][data-term="'+spl[1]+'"]').dataset;
              } else if(spl[0] == "gama") {
                loadmore_params += document.querySelector('.sidebar-gama-item input[type=checkbox][data-term="'+spl[1]+'"]').dataset;
              } else {
                loadmore_params += document.querySelector('#orderby_loadmore').dataset;
              }
            }
          }
        }*/

        function filterClick(url, flag, key, value) {
          var transition = "fade";
          var speed = 250;
          var data;

          if (key == "range") {
            $("#price_loadmore").attr("data-meta-value", value);
            data = document.querySelector("#price_loadmore").dataset;
          } else if (key == "product_cat") {
            data = document.querySelector(
              '.sidebar-category-item input[type=checkbox][data-term="' +
                value +
                '"]'
            ).dataset;
          } else if (key == "gama") {
            data = document.querySelector(
              '.sidebar-gama-item input[type=checkbox][data-term="' +
                value +
                '"]'
            ).dataset;
          } else {
            data = document.querySelector("#orderby_loadmore").dataset;
          }

          // Call Ajax Load More `filter` method
          ajaxloadmore.filter(transition, speed, data);

          // atualiza o estado do histórico do browser e o url
          // se navegar com botões trás e frente do browser atualiza o estado que já tinha sido criado
          if (flag == "update") {
            window.history.replaceState(
              "Merchandising",
              "Merchandising - Honda Merch",
              url
            );
          } else {
            window.history.pushState(
              "Merchandising",
              "Merchandising - Honda Merch",
              url
            );
          }

          atualizaInputs(url); // atualiza inputs que vêm do url
        }

        // callback que ocorre sempre que o pedido loadmore é terminado
        window.almComplete = function (alm) {
          $(".total_accessories").html(alm.totalposts).fadeIn();

          var productimageSwiper = new Swiper(
            ".product-image.swiper-container",
            {
              noSwiping: true,
              slidesPerView: 1,
              loop: true,
              touchRatio: 0,
              on: {
                init: function () {
                  const numberOfSlides = this.slides.length - 2;
                  if (numberOfSlides == 1) {
                    $(this.params.el)
                      .find(".swiper-navigation, .swiper-pagination")
                      .css("display", "none");
                  }
                },
              },
              pagination: {
                el: "#product-image .swiper-pagination",
                type: "progressbar",
              },
              navigation: {
                nextEl: "#product-image .arrow-next",
                prevEl: "#product-image .arrow-prev",
              },
            }
          );
        };

        // callback que ocorre sempre que haja alteração do pedido loadmore
        window.almOnChange = function (alm) {
          var taxonomy = alm.content.dataset.taxonomy;
          var taxonomy_terms = alm.content.dataset.taxonomyTerms;
          var taxonomy_operator = alm.content.dataset.taxonomyOperator;
          var orderby = alm.content.dataset.orderby;
          var order = alm.content.dataset.order;
          var range = alm.content.dataset.metaValue;

          var spl_taxonomy = taxonomy.split(":");
          var spl_taxonomy_terms = taxonomy_terms.split(":");

          var operator = [];
          for (var i = 0; i < spl_taxonomy_terms.length; i++) {
            operator.push("IN");
          }
          taxonomy_operator = operator.join(":");

          // atualiza atributos loadmore das checkboxes gamas
          $(".sidebar-gama").each(function () {
            var gama = $(this).data("term");

            if (taxonomy.indexOf(":gama") !== -1) {
              var idx = spl_taxonomy.indexOf("gama");
              var termo = spl_taxonomy_terms[idx];
              var taxonomy_terms_temp = taxonomy_terms.replace(termo, gama);

              $(this).attr("data-taxonomy", taxonomy);
              $(this).attr("data-taxonomy-terms", taxonomy_terms_temp);
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            } else {
              $(this).attr("data-taxonomy", taxonomy + ":gama");
              $(this).attr("data-taxonomy-terms", taxonomy_terms + ":" + gama);
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            }
          });

          // atualiza atributos loadmore das checkboxes categorias
          $(".sidebar-checkbox").each(function () {
            var cat_url = $(this).data("term");

            if (taxonomy.indexOf(":product_cat") !== -1) {
              var idx = spl_taxonomy.indexOf("product_cat");
              var termo = spl_taxonomy_terms[idx];
              var taxonomy_terms_temp = taxonomy_terms.replace(termo, cat_url);

              $(this).attr("data-taxonomy", taxonomy);
              $(this).attr("data-taxonomy-terms", taxonomy_terms_temp);
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            } else {
              $(this).attr("data-taxonomy", taxonomy + ":product_cat");
              $(this).attr(
                "data-taxonomy-terms",
                taxonomy_terms + ":" + cat_url
              );
              $(this).attr("data-taxonomy-operator", taxonomy_operator);
            }
          });

          // actualiza atributos loadmore do campo escondido orderby_loadmore
          if (orderby) {
            if (orderby == "meta_value_num") {
              $("#orderby_loadmore").attr("data-orderby", orderby);
              $("#orderby_loadmore").attr("data-meta-key", "_price");
            } else {
              $("#orderby_loadmore").attr("data-orderby", orderby);
              $("#orderby_loadmore").attr("data-meta-key", "");
            }
          }
          if (order) {
            $("#orderby_loadmore").attr("data-order", order);
          }

          // actualiza slider de preços
          //var cat = "merchandising";
          var cat = 132;
          var data = {
            action: "refresh_price_slider",
            post_type: "POST",
            cat: cat,
            url: window.location.href,
          };

          $.post(ajaxurl, data, function (response) {
            $(".price-input-wrap").html(response);

            var minRange;
            var maxRange;
            var rangeParam = getParameterByName("range");
            if (rangeParam != null) {
              var res = rangeParam.split(",");
              //console.log(res);
              minRange = Math.floor(res[0]);
              maxRange = Math.ceil(res[1]);
            } else {
              minRange = Math.floor($("#min-amount").val());
              maxRange = Math.ceil($("#max-amount").val());
            }

            //Function to Operate the Price Range Slider
            var minPrice = Math.floor($("#min-amount").val());
            var maxPrice = Math.ceil($("#max-amount").val());
            $("#slider-range").slider({
              range: true,
              min: minPrice,
              max: maxPrice,
              values: [minRange, maxRange],
              slide: function (event, ui) {
                for (var i = 0; i < ui.values.length; ++i) {
                  $("input.price-range-input[data-index=" + i + "]").val(
                    ui.values[i]
                  );
                  resetActive();
                }
              },
            });

            $("#min-amount").val(minRange);
            $("#max-amount").val(maxRange);
            $("#price_loadmore").attr(
              "data-meta-value",
              minRange + "," + maxRange
            );
          });
        };

        function atualizaInputs(url) {
          // gamas
          if (url.indexOf("gama") !== -1) {
            var gama = getParameterByName("gama", url);
            $(
              ".sidebar-gama-item input[type='checkbox'][data-term!='" +
                gama +
                "']"
            ).each(function () {
              $(this).prop("checked", false);
            });
          }

          // categorias
          if (url.indexOf("product_cat") !== -1) {
            var cat_url = getParameterByName("product_cat", url);
            $(
              ".sidebar-category-item input[type='checkbox'][data-term!='" +
                cat_url +
                "']"
            ).each(function () {
              $(this).prop("checked", false);
            });
          }

          // slider
          var minRange = Math.floor($("#min-amount-bd").val());
          var maxRange = Math.ceil($("#max-amount-bd").val());
          var minStart = minRange;
          var maxStart = maxRange;

          if (url.indexOf("range") !== -1) {
            var range = getParameterByName("range", url).split(",");
            minRange = Math.floor(range[0]);
            maxRange = Math.ceil(range[1]);

            minStart = Math.floor($("#min-amount-bd").val());
            maxStart = Math.ceil($("#max-amount-bd").val());
          }

          $("#slider-range").slider({
            range: true,
            min: minStart,
            max: maxStart,
            values: [minRange, maxRange],
            slide: function (event, ui) {
              for (var i = 0; i < ui.values.length; ++i) {
                $("input.price-range-input[data-index=" + i + "]").val(
                  ui.values[i]
                );
                resetActive();
              }
            },
          });

          $("#min-amount").val(minRange);
          $("#max-amount").val(maxRange);
          // fim slider

          // order by
          if (url.indexOf("orderby") !== -1) {
            var orderby = getParameterByName("orderby", url);
            var order = getParameterByName("order", url);

            $("#product-sort")
              .find("li")
              .each(function () {
                $(this).removeClass("active");
              });
            $("a[data-sort='" + orderby + "'][data-order='" + order + "']")
              .parent()
              .addClass("active");
          } else {
            $("#product-sort")
              .find("li")
              .each(function () {
                $(this).removeClass("active");
              });
          }
          // end order by
        }

        // quando se navega no histórico do browser (botão frente e atrás) atualiza o estado criado antes para não criar
        // sempre o mesmo histórico no browser, senão navegamos sempre na mesma página
        window.onpopstate = function (event) {
          //console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));
          //loadMerchandising(document.location.href, "update");
          location.reload();
        };

        var activityTimeout;

        // No activity do something.
        function inActive() {
          var priceRangeLow = $("#min-amount").val();
          var priceRangeHigh = $("#max-amount").val();
          getCurrentParameters(
            "range",
            priceRangeLow + "," + priceRangeHigh,
            ""
          );
        }

        function resetActive() {
          clearTimeout(activityTimeout);
          activityTimeout = setTimeout(inActive, 2000);
        }

        // JavaScript to be fired on the about us page
        var minRange;
        var maxRange;
        var rangeParam = getParameterByName("range");
        if (rangeParam != null) {
          var res = rangeParam.split(",");
          //console.log(res);
          minRange = Math.floor(res[0]);
          maxRange = Math.ceil(res[1]);
        } else {
          minRange = Math.floor($("#min-amount").val());
          maxRange = Math.ceil($("#max-amount").val());
        }
        //Function to Operate the Price Range Slider
        var minPrice = Math.floor($("#min-amount").val());
        var maxPrice = Math.ceil($("#max-amount").val());
        $("#slider-range").slider({
          range: true,
          min: minPrice,
          max: maxPrice,
          values: [minRange, maxRange],
          slide: function (event, ui) {
            for (var i = 0; i < ui.values.length; ++i) {
              $("input.price-range-input[data-index=" + i + "]").val(
                ui.values[i]
              );
              resetActive();
            }
          },
        });

        $("#min-amount").val(minRange);
        $("#max-amount").val(maxRange);
        $("#price_loadmore").attr("data-meta-value", minRange + "," + maxRange);

        $("input.price-range-input").change(function () {
          var $this = $(this);
          $("#slider-range").slider("values", $this.data("index"), $this.val());
          resetActive();
        });

        $("a.sort-item-btn").click(function () {
          $("a.sort-item-btn").removeClass("active");
          $(this).addClass("active");
          var btnKey = $(this).attr("data-sort");
          var btnValue = $(this).attr("data-order");
          var btnExtra = "sorting";
          if (btnKey == "_price") {
            $("#orderby_loadmore").attr("data-orderby", "meta_value_num");
            $("#orderby_loadmore").attr("data-meta-key", btnKey);
          } else {
            $("#orderby_loadmore").attr("data-orderby", btnKey);
            $("#orderby_loadmore").attr("data-meta-key", "");
          }
          $("#orderby_loadmore").attr("data-order", btnValue);
          $("#sort-toggle").click();
          getCurrentParameters("orderby", btnKey, btnValue);
        });

        $(".sidebar-gama").change(function () {
          var gama = $(this).data("term");
          if ($(this).prop("checked")) {
            getCurrentParameters("gama", gama, "");
          } else {
            var url = document.location.href;
            url = url.replace("?gama=" + gama, "").replace("&gama=" + gama, "");
            if (url.indexOf("?") === -1) url = url.replace("&", "?");
            window.history.pushState(
              "Merchandising",
              "Merchandising - Honda Merch",
              url
            );
            window.location.href = url;
          }
        });

        $(".sidebar-checkbox").change(function () {
          var cat_url = $(this).data("term");
          if ($(this).prop("checked")) {
            getCurrentParameters("product_cat", cat_url, "");
          } else {
            var url = document.location.href;
            url = url
              .replace("?product_cat=" + cat_url, "")
              .replace("&product_cat=" + cat_url, "");
            if (url.indexOf("?") === -1) url = url.replace("&", "?");
            window.history.pushState(
              "Merchandising",
              "Merchandising - Honda Merch",
              url
            );
            window.location.href = url;
          }
        });

        function findActiveFilters() {
          var orderbyParam = getParameterByName("orderby");
          var orderedParam = getParameterByName("order");
          var gamaParam = getParameterByName("gama");
          var catParam = getParameterByName("product_cat");
          //var rangeParam = getParameterByName('range');
          if (orderbyParam != null) {
            var activeSort = $(
              'a[data-sort="' +
                orderbyParam +
                '"][data-order="' +
                orderedParam +
                '"]'
            );
            activeSort.addClass("active");
            $("#current-sort").html(activeSort.html());
          } //
          if (gamaParam != null) {
            $("#gama-" + gamaParam).prop("checked", true);
          }
          if (catParam != null) {
            $("#category-" + catParam).prop("checked", true);
          }
        }
        findActiveFilters();

        $("#sort-toggle").click(function () {
          $(this).toggleClass("open");
          $("#product-sort").toggleClass("active");
        });
      },
    },

    // Contacts
    contacts: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {},
    },
    // FAQs
    faqs: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {},
    },
  };

  $(".conduza-before").paroller({
    factor: 0.08,
    factorXs: 0.2,
    type: "foreground",
    direction: "vertical",
  });
  $(".products-wrapper-before").paroller({
    factor: 0.08,
    factorXs: 0.2,
    type: "foreground",
    direction: "vertical",
  });
  $(".instagram-before").paroller({
    factor: 0.08,
    factorXs: 0.2,
    type: "foreground",
    direction: "vertical",
  });

  /*$(".insta-one").paroller({ factor: 0.08, factorXs: 0.2, type: 'foreground', direction: 'vertical' });
  $(".insta-two").paroller({ factor: 0.09, factorXs: 0.2, type: 'foreground', direction: 'vertical' });
  $(".insta-three").paroller({ factor: 0.08, factorXs: 0.2, type: 'foreground', direction: 'vertical' });
  $(".insta-four").paroller({ factor: 0.1, factorXs: 0.2, type: 'foreground', direction: 'vertical' });*/

  var slidesProdutos = 3;
  if (window.location.href.indexOf("produto") !== -1) slidesProdutos = 4;

  var productSwiper = new Swiper("#products-wrapper #product-slides", {
    slidesPerView: slidesProdutos,
    speed: 1000,
    spaceBetween: 30,
    slidesPerGroup: slidesProdutos,
    watchOverflow: true,
    loop: true,
    pagination: {
      el: "#products-wrapper #out-slider-pag",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1,
    },
    /*autoplay: {
      delay: 5000,
    },*/
    /*
    navigation: {
      nextEl: '.clients_slide.swiper-button-next',
      prevEl: '.clients_slide.swiper-button-prev',
    },*/
    breakpoints: {
      1000: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
        slidesPerGroup: 2,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
    },
  });

  var productimageSwiper = new Swiper(".product-image.swiper-container", {
    noSwiping: true,
    slidesPerView: 1,
    loop: true,
    touchRatio: 0,
    on: {
      init: function () {
        const numberOfSlides = this.slides.length - 2;
        if (numberOfSlides == 1) {
          $(this.params.el)
            .find(".swiper-navigation, .swiper-pagination")
            .css("display", "none");
        }
      },
    },
    pagination: {
      el: "#product-image .swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: "#product-image .arrow-next",
      prevEl: "#product-image .arrow-prev",
    },
  });

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $("#tamanho").select2({
    minimumResultsForSearch: 20, // at least 20 results must be displayed
  });

  $(".page-share-wrapper").on("click", function (e) {
    e.preventDefault();
    $(".modal").toggleClass("is-visible");
  });

  $(".modal button.modal-close.modal-toggle").on("click", function (e) {
    e.preventDefault();
    $(".modal").toggleClass("is-visible");
  });

  // Menu Mobile
  $(".burger-container").on("click", function () {
    $(".header").toggleClass("menu-opened");
    $(".menu-bg, .main-menu-mobile").toggleClass("active");
    $("body").toggleClass("over");
    $("html").toggleClass("over");
  });

  $("a.anchor-mobile").on("click", function () {
    $(".header").removeClass("menu-opened");
    $("body").removeClass("over");
  });

  $("a.top-button").on("click", function () {
    $(".header").removeClass("menu-opened");
    $("body").removeClass("over");
  });
  // End of Menu Mobile

  $("#filter-all").click(function () {
    $("a.item-filter").removeClass("active");
    checkfilters();
  });
  // End of Ajax Post Categories

  /* menu footer */
  $("footer .col-md-2:not(:last-child) h5").click(function () {
    console.log($(window).width() < 1001);
    if ($(window).width() < 1001) {
      $(this).toggleClass("active").parent().find(".main-menu").slideToggle();
    }
  });
  /* End menu footer */

  /* filtro listagens produtos */
  $("#filter-btn").click(function () {
    $(this).toggleClass("active");
    $("#store-filters").slideToggle();
    if ($("#product-sort").hasClass("active")) {
      $("#sort-toggle").toggleClass("open");
      $("#product-sort").hide().toggleClass("active");
    }
  });
  /* End filtro listagens produtos */

  /* organizar listagens produtos */
  $("#sort-toggle").click(function () {
    if ($(window).width() < 1001) {
      $("#product-sort").slideToggle();
      if ($("#store-filters").css("display") == "block") {
        $("#filter-btn").toggleClass("active");
        $("#store-filters").hide();
      }
    }
  });
  /* End organizar listagens produtos */

  function goBack() {
    window.history.back();
  }

  /*$('.wpgis-slider-for').on('swipe', function(event, slick, direction){
  if(direction == "down") {
    $('html, body').animate({
      scrollTop: $(".product-info").offset().top-50
    }, 500);
  } else {
    $('html, body').animate({
      scrollTop: 0
    }, 500);
  }
});*/

  if ($(".single-product").length > 0) {
    if ($("#pa_tamanho").length > 0) {
      $("#pa_tamanho").select2({
        minimumResultsForSearch: -1,
      });
    }
  }

  if ($(".page-template-atualizaPwd").length > 0) {
    var wpcf7Elm = document.querySelector(".wpcf7");

    $(document).ready(function () {
      $(".page-template-atualizaPwd input[name='your-email']").val(
        $(".page-template-atualizaPwd #link_email").val()
      );
    });
  }
})(jQuery); // Fully reference jQuery after this point.
